export default class Transition {
  $body = $(document.body);
  $document = $(document);
  $window = $(window);
  
  constructor() {
    this.animationEvent = "animationend webkitAnimationEnd animationend oAnimationEnd";
    this.isSliding = false;

    this.addEvents();
  }

  addEvents() {
    this.$wrapper = $(document.body);
    let that = this;

    $document.on("animation:started", function() {
      $body.addClass("transition-animating");
    });

    $document.on("animation:complete", function() {
      $body.removeClass("transition-animating");
    });

    $document.on(this.animationEvent, ".in", function() {
      $(this).removeClass("slide in reverse");
      this.isSliding = false;
      $document.trigger("animation:complete");
    });

    $document.on(this.animationEvent, ".out", function() {
      $(this).removeClass("current out slide reverse");
      $document.trigger("animation:complete");
    });

    $document.on(R.touchEvent, ".slideable-trigger", function(e) {
      var $this = $(this);

      if (this.isSliding) {
        return;
      }

      if ( $($this.data("to")).hasClass("current") ) {
        return false;
      }

      that.slide($this.data("from"), $this.data("to"), $this.data("direction"));

      if(!$this.data('allowLink')) {
        e.preventDefault();
      }
    });

    $document.on(R.touchEvent, ".fadeTop .close-icon", function(event) {
      event.preventDefault();
      this.fadeTop();
    }.bind(this));

    $window.keyup(function(e) {
      if (e.keyCode === 27 && $(".fadeTop.current").length > 0) {
        this.fadeTop();
      }
    }.bind(this));

    $document.bind("turbo:before-fetch-request", this.removeEvents.bind(this));
  }

  removeEvents() {
    $document
      .off(R.touchEvent, ".fadeTop .close-icon")
      .off("animation:complete")
      .off("animation:started");

    this.$wrapper
      .off(R.touchEvent, ".slideable-trigger")
      .off(this.animationEvent, ".out")
      .off(this.animationEvent, ".in");

    $window.unbind("keyup");
  }

  slide(from, to, direction) {
    $document.trigger("animation:started");

    this.isSliding = true;

    if (!direction) {
      direction = '';
    }

    if (!Modernizr.cssanimations) {
      $(".slideable").removeClass("current");
      $(to).addClass("current");
      this.isSliding = false;
    } else {
      $(from).addClass("slide out "+direction);
      $(to).addClass("slide current in "+direction);
    }

    $body.trigger("view:change", to);

    $("html, body").animate({
      scrollTop: $(to).offset().top - 100
    });
  }

  fadeTop(element) {
    var $element = $(element);
    $document.trigger("animation:started");

    if ($element[0] === $(".fadeTop.current")[0]) {
      return;
    }

    $("#shadow").remove();

    if (!Modernizr.cssanimations) {
      $(".fadeTop.current").removeClass("current out in");
    } else {

      $(".fadeTop.current").addClass("out");
      $("body.overlay-open").removeClass("overlay-open");
    }

    if (element) {
      var $shadowDiv = $("<div id='shadow' style='height: 100%; width: 100%; position: fixed; top: 0; left: 0; z-index: 1000; background: black; opacity: 0.7'>");
      $body.append($shadowDiv);
      $shadowDiv.one(R.touchEvent, function() { R.transition.fadeTop() });

      $("body").addClass("overlay-open");
      $(element).addClass("current");
    }

    if (window.outerWidth && window.outerWidth <= 600) {
      this.$wrapper.scrollTop(1);
    }
  }

  show(from, to) {
    if (from) {
      $(from).removeClass("current out");
      $(to).addClass("current");
    } else {
      $(to).addClass("current");
    }
    $body.trigger("view:change", to);
  }
}
